.intro-main{
    font-size:18px;
    text-align:left;
    display: table;
    width: 100%;
    height: 100%;
    position: relative;
}

@media only screen and (max-width : 768px) { 
    .intro-main{
        overflow: auto;
    }
  }
  
.intro-main .col .main-text{
    position: relative;
    transform: translateY(-50%);
}

.col.word-cloud{
    height: 100%;
}