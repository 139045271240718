.experience-block{
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.row.experience{
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

@media only screen and (max-width : 768px) { 
    .row.experience{
        overflow: auto;
        display: block;
        height: fit-content;
        margin-left:5%;
    }
  }

.work-list{
    height: 100%;
    width: 100%;
    border-right: 1px solid #333;
}

.education-list{
    height: 100%;
    width: 100%;
}

.section-title h2 u{
    font-weight: bold;
}

.work-title{
    padding-top: 5px;
    height: max-content;
    border-style: dashed;
    border-right: 0;
    border-top: 0;
    border-left: 0;
    border-color: rgb(131, 128, 128);
}