.addinfo{
    overflow-x: hidden;
    overflow-y: auto;
    position:relative;
}

.addinfo, .addinfo .addinfo-main{
    height: 100%;
    align-items: center;
}

@media only screen and (max-width : 768px) { 
    .addinfo, .addinfo .addinfo-main{
        height: 100%;
        display: block;
    }
    .row .contact-info{
        display: block;
        margin-top: 10px;
    }
    .row .resources-info{
        display: block;
        margin-top: 10px;
    }

    .row .resume-download{
        display: block;
        margin-top: 15%;
    }
  }

.contact-info, .resourcese-info{
    text-align: center;
}

.col-10{
    border-style: solid;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    margin-bottom: 5px;
    margin-top: 5px;
}

.resume-download button{
    display: inline-block;
    color: white;
    font-size: 30px;
    font-weight: bold;
    border-radius: 5px;
    width: 100%;
    position: relative;
    height: 50px;
    border: solid;
}

.resource-links div a{
    position: relative;
}