.row.middle{
  height: 80vh;
  display: flex;
}

div.intro-block{
  height:100%;
}

div.experience-block{
  height: 100%;
}

div.project-block, div.addinfo-block{
  height: 100%;
}

div.content {
  padding: 2%;
  height: 80%;
}

div.indicator {
  height: 20%;
  display: flex;
  justify-content: space-around;
  background-color: black;
}

div.indicator button{
  color: rgb(186, 178, 178);
  padding: 8px 12px;
  margin: 4px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  position: relative;
  overflow: hidden;
  transition: 0.1s;
  width: 100%;
  height: 50%;
}

div.indicator button:hover{
  color: #fff;
}

div.indicator button:hover:before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

div.indicator button:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(209, 202, 202);
  z-index: -1;
  transform: scale(0.3);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
}

div.indicator button:active{
  background: rgb(109, 108, 108);
  color: #fff;
  font-weight: bolder;
  border-radius: 4px;
}

div.indicator button:focus{
  background:rgb(109, 108, 108);
  color: #fff;
  font-weight: bolder;
  border-radius: 4px;
}

@media only screen and (max-width : 768px) { 
  div.indicator{
    display: block;
    height: fit-content;
  }
}

.col-sm-12.text-right{
  position: relative;
  height: 12vh;
  background: linear-gradient(black, white);
}

button{
  height: 100%;
  background: transparent;
  border: 0;
}

img{
  height: 80%;
}

@media only screen and (max-width : 768px) { 
  .col-sm-12.text-right{
    height: 100px;
  }
}